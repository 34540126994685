<template>
  <v-row dense>
    <v-col cols="auto"><v-switch dense v-model="isErs" color="info" label="전문수신 알람"></v-switch></v-col>
    <v-col cols="auto"><v-switch dense v-model="isMessage" color="info" label="메세지 알람"></v-switch></v-col>
  </v-row>
</template>

<script>
import notify from '@/utils/notify'
import { mapGetters } from 'vuex'
import vs from '@/utils/viewSupport'

export default {
  name: 'SoundBar',
  data() {
    return {
      isErs: false,
      isMessage: false,
      currentVoice: null,
      currentLang: 'ko-KR'
    }
  },
  methods: {
    setCurrentVoice() {
      if (!window.speechSynthesis) {
        notify.showError('음성 재생을 지원하지 않는 브라우저입니다. 크롬, 파이어폭스 등의 최신 브라우저를 이용하세요')
      } else {
        const voices = window.speechSynthesis.getVoices()
        this.currentVoice = voices.find(e => e.lang.indexOf(this.currentLang) >= 0 || e.lang.indexOf(this.currentLang.replace('-', '_')) >= 0)
        console.log(this.currentVoice)
      }
    },
    tts(msg) {
      const utter = new SpeechSynthesisUtterance(msg)
      if (this.currentVoice) {
        utter.voice = this.currentVoice
        utter.lang = this.currentLang
      }
      utter.onerror = function(e) {
        console.log(JSON.stringify(e))
      }
      window.speechSynthesis.speak(utter)
    },
    ersHandler(msg) {
      if (msg && msg.DATA && msg.CMD) {
        const ers = msg.DATA
        const custName = vs.renderCustCode(ers.custCode)
        if (msg.CMD === 'ERS_RECV' && ers.ersId) {
          const number = Number(ers.ersId.split('-').pop())
          const speechText = `${custName}, ${number}번, 긴급출동요청, 수신`
          this.tts(speechText)
        }
      }
    },
    messageHandler(msg) {
      console.log(JSON.stringify(msg))
      if (msg.message) {
        this.tts('메시지 수신')
      }
    }
  },
  mounted() {
    this.setCurrentVoice()
  },
  beforeDestroy() {
    if (this.ws) {
      this.ws.removeListener('ers-message', this.ersHandler)
      this.ws.removeListener('message', this.messageHandler)
    }
  },
  computed: {
    ...mapGetters(['ws'])
  },
  watch: {
    isErs(v) {
      if (v) {
        notify.showMessage('전문수신 알람이 켜졌습니다.')
        this.tts('전문수신 알람이 켜졌습니다.')
        this.ws.on('ers-message', this.ersHandler)
      } else {
        notify.showMessage('전문수신 알람이 꺼졌습니다.')
        this.ws.removeListener('ers-message', this.ersHandler)
      }
    },
    isMessage(v) {
      this.$store.state.messageAlarm = v
      console.log('state.messageAlarm --->', this.$store.state.messageAlarm)
      if (v) {
        notify.showMessage('메시지 알람이 켜졌습니다.')
        this.tts('메시지 알람이 켜졌습니다.')
        this.ws.on('message', this.messageHandler)
      } else {
        notify.showMessage('메시지 알람이 꺼졌습니다.')
        this.ws.removeListener('message', this.messageHandler)
      }
    }
  }
}
</script>

<style scoped>

</style>
